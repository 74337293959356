import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as moment from "moment";
import { Card, Alert } from "react-bootstrap";
import FloatingWhatsAppButton from "../../components/WhatsappButton/FloatingWhatsAppButton"

const VistaPreviaExamen = () => {
	const { examen, preguntas } = useSelector((state) => state.cursos);
	const { sesion } = useSelector((state) => state.sesiones);
	const { idCurso } = useParams();
	const puntaje = (preguntas_) => {
		let puntaje_total = 0;
		preguntas_.map((pregunta) => {
			puntaje_total += parseInt(pregunta.puntaje);
		});
		return puntaje_total;
	};
	return (
		<Layout>
			<div className="container content mt-5">
				{examen ? (
					examen.nombre_examen && examen.nombre_examen !== "" ? (
						<>
							{" "}
							<div style={{ maxWidth: "900px", margin: "auto" }}>
								{
									//<div style={{height:'150px', background: "url('https://intranet.inedi.edu.pe/images/cabecera_examen.png') center center no-repeat", backgroundSize:"cover", marginBottom:"10px", borderRadius:"8px"}}></div>
								}

								<Card
									style={{
										borderTop: "#27314A 5px solid",
										marginBottom: "15px",
									}}
								>
									<Card.Body>
										<h1>{examen.nombre_examen}</h1>
										<p style={{ fontSize: "18px" }}>
											{examen
												? examen.descripcion_examen
												: ""}
										</p>
										<div className="content-body-proyecto">
											<div className="resumen">
												<div className="row p-3">
													<div className="col-md-4">
														<div className="content-info">
															<div className="info">
																<p className="font-weight-bold text-label">
																	Fecha de
																	entrega
																</p>
																<p className="ml-2 text-lowercase">
																	{sesion
																		? moment(
																				sesion.fecha_sesion
																		  ).format(
																				"DD MMM YYYY"
																		  )
																		: ""}
																</p>
															</div>
															<div className="info">
																<p className="font-weight-bold text-label">
																	Límite de
																	Tiempo
																</p>
																<p className="ml-2 text-lowercase">
																	{examen
																		? examen.duracion
																		: ""}{" "}
																	minutos.
																</p>
															</div>
														</div>
													</div>
													<div className="col-md-4">
														<div className="content-info">
															<div className="info">
																<p className="font-weight-bold text-label">
																	Puntos
																</p>
																<p className="ml-2">
																	0
																	{/*puntaje(preguntas)*/}
																</p>
															</div>
															<div className="info mt-2">
																<p className="font-weight-bold text-label">
																	Preguntas
																</p>
																<p className="ml-2">
																	{
																		preguntas.length
																	}
																</p>
															</div>
														</div>
													</div>
													<div className="col-md-4">
														<div className="content-info">
															<div className="info mt-2">
																<p className="font-weight-bold text-label">
																	Intentos
																</p>
																<p className="ml-2">
																	Ilimitados
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Card.Body>
								</Card>
								<Card
									style={{
										borderTop: "#27314A 5px solid",
										marginBottom: "15px",
									}}
								>
									<Card.Body>
										{sesion.id_sesion !== "384" ? (
											<div>
												<h2>Instrucciones:</h2>
												<p>
													Ahora que comprendes{" "}
													<strong>
														{" "}
														{sesion.nombre_sesion}
													</strong>
													, contesta este breve Quiz
													para reforzar los conceptos
													y aprendizaje clave.
												</p>
												<br></br>
												<p>
													<strong>
														Por favor, toma nota:{" "}
													</strong>
													Este quiz está diseñado para
													ayudarte a medir tu propio
													nivel de conocimientos y
													comprensión. Si te equivocas
													en más de una pregunta te
													recomendamos revisar una vez
													más y después tratar de
													responder nuevamente el
													quiz. Sin embargo, si
													obtienes una calificación
													superior a 14 en el primer
													intento ganarás 10 puntos en
													el Gestora Rank.
												</p>
                                                <br></br>
                                                <p>
                                                    ¡Éxitos!
                                                </p>
											</div>
										) : (
											<div>
												<p>
													<strong>
														{" "}
														Simulacro de Evaluación
														de Certificación por
														niveles - OSCE
													</strong>
												</p>
												<p>
													Con el fin de medir tu nivel
													de conocimiento antes de
													rendir el examen de
													certificación por niveles –
													OSCE, hemos seleccionado
													este balotario de preguntas.
												</p>
												<br></br>
												<p>
													<strong>Ventajas:</strong>
												</p>
												<p>
													<ul
														style={{
															marginLeft: "15px",
														}}
													>
														<li>
															Reconocer el nivel
															de conocimiento al
															rendir el Simulacro
															de Evaluación.
														</li>

														<li>
															Identificar nuestras
															potenciales fallas
															ante un posible
															Examen de
															Certificación.
														</li>

														<li>
															Identificar las
															posibles preguntas
															en un Examen de
															Certificación.
														</li>

														<li>
															{" "}
															Mejorar
															considerablemente
															los puntos claves en
															las Contrataciones
															para rendir con
															éxito el Examen de
															Certificación.
														</li>
													</ul>
												</p>
											</div>
										)}
										<div className="text-center mt-5">
											<Link
												className="btn btn-primary"
												to={"actividad/resolver"}
											>
												{sesion.id_sesion !== "384"
													? "Resolver Quiz"
													: "Iniciar Simulacro"}
											</Link>
										</div>
									</Card.Body>
								</Card>
							</div>
						</>
					) : (
						<Alert variant="info">
							No hay Actividad para esta sesión.{" "}
							<Link
								className="btn btn-primary"
								to={"/capacitaciones/" + idCurso + "/sesiones"}
								style={{ marginBottom: "10px" }}
							>
								{" "}
								Volver a Sesiones{" "}
							</Link>
						</Alert>
					)
				) : (
					<div
						className="w-100 h-100 d-flex align-items-center"
						style={{
							minHeight: "500px",
							background: "rgba(0,0,0,.01)",
						}}
					>
						<div className="sk-folding-cube ">
							<div className="sk-cube1 sk-cube "></div>
							<div className="sk-cube2 sk-cube "></div>
							<div className="sk-cube4 sk-cube "></div>
							<div className="sk-cube3 sk-cube "></div>
						</div>
					</div>
				)}
			</div>
			<FloatingWhatsAppButton />
		</Layout>
	);
};

export default VistaPreviaExamen;
