import {
  examenesTypes,
  ELIMINAR_CURSOS,
  LISTAR_CURSOS,
  MOSTRAR_PAGOS,
  MOSTRAR_SESIONES,
  MOSTRAR_SESIONES_EXITO,
  REMOVER_ESTADO_PAGOS,
  LISTAR_CURSO,
  LISTAR_NOTAS,
  LISTAR_ASISTENCIA,
  LISTAR_RECURSOS,
  SUBIR_TAREAS,
  LISTAR_TAREAS,
  LISTAR_SESION_CURSO,
  ELIMINAR_TAREA,
  LISTAR_VIDEOS,
  ELIMINAR_DATOS_SESIONES,
  LISTAR_PROYECTOS,
  LISTAR_PROYECTO,
  LISTAR_TAREA_ESTUDIANTE,
  temasTypes
} from "./types";

import authAxios from "../config/authAxios";
import Swal from "sweetalert2";
import {consultarPuntos} from "./usuarioActions"
//import { showEncuesta } from './encuestaActions';


export const guardarRespuestasExamen = (datosExamen) => {
 // console.log(datosExamen)
 
  return async dispatch => {
       const swalWithBootstrapButtons = Swal.mixin({
     });
         swalWithBootstrapButtons
         .fire({
             title: "¿Estas seguro?",
             text: "Se guardarán tus respuestas y se desactivará el exámen",
             icon: "info",
             showCancelButton: true,
             confirmButtonText: "Si, continuar!",
             cancelButtonText: "No, cancelar!",
             reverseButtons: true,
             showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
             confirmButtonColor: "#1C56FF",
             cancelButtonColor: "#29d698",
             preConfirm: async function() {
             //consultando para ver el mensaje de aprovación
             if(localStorage.getItem('TiempoFinal') !== undefined && localStorage.getItem("TiempoFinal"))
              {
                  localStorage.removeItem("TiempoFinal");
              }
             try {
              let respuesta = await authAxios.post(`/admin/ex_guardarRespuestasExamen`, datosExamen);
               if (!respuesta.data.error) {
                   console.log(respuesta.data)
               }
                   return true;
             } catch (error) {
                 console.log(error)
                 const msg = error.response !== undefined ? error.response.data.msg : error 
                 Swal.fire({
                     icon: "error",
                     title: "Oops...",
                     text: msg
                 })
             }
             },
             allowOutsideClick: () => !Swal.isLoading()
         })
         .then(result => {
        
             if (result.value === true) {
              dispatch(consultarPuntos(datosExamen.id_usuario))
              dispatch({
                type: examenesTypes.INSERTAR_EXAMEN_EXITO
               })
             swalWithBootstrapButtons.fire(
                 "Enviado!",
                 "Se envió sus respuestas correctamente",
                 "success"
             );
           
             }
         });
     }
}

export const guardarRespuestasExamenOutTime = (datosExamen) => {
  // console.log(datosExamen)
   return async dispatch => {

              Swal.fire({
                title: 'Se acabó el tiempo!',
                html: 'Enviando respuestas....',
                timer: 1500,
                icon: 'warning',
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,                
                showLoading:true,
                allowOutsideClick: false
              }).then( async (result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  try {
                    let respuesta = await authAxios.post(`/admin/ex_guardarRespuestasExamen`, datosExamen);
                     if (!respuesta.data.error) {
                    
                     }
                         return true;
                   } catch (error) {
                       console.log(error)
                       const msg = error.response !== undefined ? error.response.data.msg : error 
                       Swal.fire({
                           icon: "error",
                           title: "Oops...",
                           text: msg
                       })
                   }
                }
              })

          
        
      }
 }
export const obtenerExamen = (idSesion,idEstudiante) => async dispatch => {
  dispatch({
    type:examenesTypes.OBTENER_EXAMEN_SESION
})
  let examen = await authAxios.get(`/admin/ex_mostrarExamenSesion/${idSesion}/${idEstudiante}`)
  //console.log(examen.data)
  if(!examen.data.error)
    { 
         dispatch({
        payload: examen.data,
        type: examenesTypes.OBTENER_EXAMEN_SESION_EXITO
         })
    }
}

export const verExamen = (idExamen,idEstudiante )=> async dispatch => {

  let respuesta = await authAxios.get(`/admin/ex_obtenerPreguntasOpciones/${idExamen}/${idEstudiante}`);
  if (!respuesta.data.error) {

      return {mensaje: true,examen:respuesta.data.preguntas}
  }
  return {mensaje: false}
}

export const verExamenResultado = (idExamen,idEstudiante) => async dispatch => {
  if(idExamen)
  {
    try {
    
        let respuesta = await authAxios.get(`/admin/ex_obtenerPreguntasOpcionesResultados/${idExamen}/${idEstudiante}`);
        if (!respuesta.data.error) {
            let datos_resultado ={
              preguntas:respuesta.data.preguntas,
              respuestas:respuesta.data.respuestas
            }
            return {mensaje: true, examen:datos_resultado}
        }
          return {mensaje: false}
        } catch (error)
        {
          let datos_resultado = {
            preguntas:[],
            respuestas:[]
          }
          return {mensaje: false, examen:datos_resultado}
        }
  }
  else{
    let datos_resultado = {
      preguntas:[],
      respuestas:[]
    }
    return {mensaje: false, examen:datos_resultado}
  }
}

export const listarProyectos = idEstudiante => async dispatch => {
    try {
    const proyectos = await authAxios.get(`/admin/pryt_Proyectos__idEstudiante/${idEstudiante}`)
   
    dispatch({
      payload: {
        proyectos: proyectos.data,
        estado: 4
       },
       type: LISTAR_PROYECTOS
    })
  
  } catch (error) {
    console.log(error)
  }
}

export const listarProximasSesiones = idEstudiante => async dispatch => {
  try {
  const proyectos = await authAxios.get(`/admin/proximasSesiones/${idEstudiante}`)

  return proyectos.data

} catch (error) {
  console.log(error)
}
}

export const listarEmpleos = idEstudiante => async dispatch => {
  try {
  const proyectos = await authAxios.get(`/admin/listarEmpleos/${idEstudiante}`)

  return proyectos.data

} catch (error) {
  console.log(error)
}
}

export const listarPodCasts = idEstudiante => async dispatch => {
  try {
  const proyectos = await authAxios.get(`/admin/listarPodCasts/${idEstudiante}`)

  return proyectos.data

} catch (error) {
  console.log(error)
}
}

export const postularEmpleo = (datos) => async dispatch => {
  try {
  const proyectos = await authAxios.post(`/admin/postularEmpleo`,datos)
  
  return proyectos.data

} catch (error) {
  console.log(error)
}
}


export const guardarHistorialVideo = (datos) => async dispatch => {
  try {
  const proyectos = await authAxios.post(`/admin/guardarHistorialVideo`,datos)
  
  return proyectos.data

} catch (error) {
  console.log(error)
}
}


export const listarDirectorio = (idProyecto,usuario) => async dispatch => {
  try {
  const respuesta = await authAxios.get(`/admin/obtenerDirectorio/${idProyecto}/${usuario}`)
  //console.log(respuesta)
 if(!respuesta.error)
 {
   return respuesta.data
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
}
}



export const traerTema = (slug,usuario=null) => async dispatch => {
  //console.log(slug,usuario)
  try {
  const respuesta = await authAxios.get(`/admin/traerTemaForo/${slug}/${usuario}`)

 if(!respuesta.error)
 {
  dispatch({
    type: temasTypes.OBTENER_TEMA_EXITO,
    payload: {
      tema: respuesta.data
    }
  })
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const traerVideo = datos => async dispatch => {
  try {
  const respuesta = await authAxios.get(`/admin/traerVideo/${datos.video}/${datos.sesion}/${datos.id_usuario}`)

 if(!respuesta.error)
 {
  dispatch({
    type: temasTypes.OBTENER_VIDEO_EXITO,
    payload: {
      video: respuesta.data
    }
  })
   return respuesta.data
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}


export const guardarComentarioVideo = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/guardarComentarioVideo`,datos)
  if(!respuesta.error)
  {
  dispatch(consultarPuntos(datos.id_usuario))
   dispatch(traerVideo(datos))
   Swal.fire({
     icon: "success",
     title: "Éxito",
     text: "Comentario publicado correctamente."
   })
  }
  else
  {
    return null
  }

} catch (error) {
  console.log(error)
  return null
}
}
export const nuevaRespuestaDiscente = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/nuevaRespuestaComentario`,datos)

 if(!respuesta.error)
 {
  dispatch(consultarPuntos(datos.id_usuario))
  dispatch(traerVideo(datos))
  Swal.fire({
    icon: "success",
    title: "Éxito",
    text: "Respuesta publicada correctamente."
  })
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const nuevaRespuestaTemaForo = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/nuevaRespuestaTemaForo`,datos)

 if(!respuesta.error)
 {
  dispatch(consultarPuntos(datos.id_usuario))
  dispatch(traerTema(datos.slug,datos.id_usuario))
  Swal.fire({
    icon: "success",
    title: "Éxito",
    text: "Respuesta publicada correctamente."
  })
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const likeComentarioTemaForo = datos => async dispatch => {
 // console.log(datos)
  try {
  const respuesta = await authAxios.post(`/admin/likeComentarioTemaForo`,datos)

 if(!respuesta.error)
 {
  dispatch(consultarPuntos(datos.id_usuario))
  dispatch(traerTema(datos.slug,datos.id_usuario))
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const likeComentarioVideo = datos => async dispatch => {
  // console.log(datos)
   try {
   const respuesta = await authAxios.post(`/admin/likeComentarioTemaForo`,datos)
 
  if(!respuesta.error)
  {
   dispatch(consultarPuntos(datos.id_usuario))
   dispatch(traerVideo(datos))
    return true
  }
  else{
    return null
  }
 
 } catch (error) {
   console.log(error)
   return null
 }
 }

export const disLikeComentarioTemaForo = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/disLikeComentarioTemaForo`,datos)

 if(!respuesta.error)
 {
  dispatch(traerTema(datos.slug,datos.id_usuario))
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const disLikeComentarioVideo = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/disLikeComentarioTemaForo`,datos)

 if(!respuesta.error)
 {
  dispatch(traerVideo(datos))
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const eliminarRespuestaTemaForo = datos => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          confirmButtonColor: "#1C56FF",
          cancelButtonColor: "#29d698",
          preConfirm: async function() {
          

     
          try {
            const respuesta = await authAxios.post(`/admin/eliminarRespuestaTemaForo`,datos)
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
              return true;
          } catch (error) {
            
            swalWithBootstrapButtons.fire(
              "Error!",
              "No se pudo borrar tu respuesta.",
              "error"
          );
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
            dispatch(traerTema(datos.slug,datos.id_usuario))
            swalWithBootstrapButtons.fire(
              "Éxito!",
              "Tu respuesta ha sido borrada.",
              "success"
          );
          }
      });
  }
}


export const eliminarRespuestaComentario = datos => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          confirmButtonColor: "#1C56FF",
          cancelButtonColor: "#29d698",
          preConfirm: async function() {
          

     
          try {
            const respuesta = await authAxios.post(`/admin/eliminarRespuestaComentario`,datos)
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
              return true;
          } catch (error) {
            
            swalWithBootstrapButtons.fire(
              "Error!",
              "No se pudo borrar tu respuesta.",
              "error"
          );
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
            dispatch(traerVideo(datos))
            swalWithBootstrapButtons.fire(
              "Éxito!",
              "Tu respuesta ha sido borrada.",
              "success"
          );
          }
      });
  }
}


export const eliminarComentarioVideo = datosComentario =>{
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          confirmButtonColor: "#1C56FF",
          cancelButtonColor: "#29d698",
          preConfirm: async function() {

          try {
          let respuesta = await authAxios.post(`/admin/eliminarComentarioVideo`, datosComentario);
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
              return true;
          } catch (error) {
            
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: 'Error al realizar la operación...'
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
          
          dispatch(traerVideo(datosComentario))
          swalWithBootstrapButtons.fire(
              "Éxito!",
              "Tu comentario ha sido borrado.",
              "success"
          );
        
          }
      });
  }
}

export const listarTemas = idProyecto => async dispatch => {
  try {
  const respuesta = await authAxios.get(`/admin/obtenerTemasForo/${idProyecto}`)
  //console.log(respuesta)
 if(!respuesta.error)
 {
  dispatch({
    type: temasTypes.OBTENER_TEMAS_EXITO,
    payload: {
      temas: respuesta.data
    }
  })
  // return respuesta.data
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
}
}


export const guardarTema = datosTema => async dispatch => {


  try {
   const respuesta = await authAxios.post(`/admin/guardarTemaForo`,datosTema)
   if(!respuesta.error)
   {
    dispatch(consultarPuntos(datosTema.id_estudiante))
    dispatch(listarTemas(datosTema.id_proyecto))
    Swal.fire({
      icon: "success",
      title: "Éxito",
      text: "Tema publicado correctamente."
    })
   }
   else
   {
     return null
   }
  
  } catch (error) {
    console.log(error)
  }

}

export const guardarComentario = datosTema => async dispatch => {


  try {
   const respuesta = await authAxios.post(`/admin/guardarComentarioTemaForo`,datosTema)
   if(!respuesta.error)
   {
    dispatch(consultarPuntos(datosTema.id_usuario))
    dispatch(traerTema(datosTema.slug,datosTema.id_usuario))
    Swal.fire({
      icon: "success",
      title: "Éxito",
      text: "Comentario publicado correctamente."
    })
   }
   else
   {
     return null
   }
  
  } catch (error) {
    console.log(error)
  }

}


export const eliminarComent = datosComentario =>{
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          confirmButtonColor: "#1C56FF",
          cancelButtonColor: "#29d698",
          preConfirm: async function() {

          try {
           let respuesta = await authAxios.post(`/admin/eliminarComentarioTemaForo`, datosComentario);
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
                return true;
          } catch (error) {
            
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: 'Error al realizar la operación...'
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
          dispatch(traerTema(datosComentario.tema,datosComentario.id_usuario))
          swalWithBootstrapButtons.fire(
              "Éxito!",
              "Tu comentario ha sido borrado.",
              "success"
          );
        
          }
      });
  }
}

export const eliminarTemaForo = datosTema =>{
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "Se borrará la información ligada a este tema.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          confirmButtonColor: "#1C56FF",
          cancelButtonColor: "#29d698",
          preConfirm: async function() {

          try {
           let respuesta = await authAxios.post(`/admin/eliminarTemaForo`, datosTema);
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
                return true;
          } catch (error) {
            
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: 'Error al realizar la operación...'
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
          dispatch(listarTemas(datosTema.proyecto))
          swalWithBootstrapButtons.fire(
              "Éxito!",
              "El tema ha sido correctamente.",
              "success"
          );
        
          }
      });
  }
}

export const showForm = () => {
  return dispatch => {
    dispatch({
      type: temasTypes.SHOW_FORM
    })
  }
}

export const listarProyecto = (idEstudiante, idProyecto) => async dispatch => {
  try {
    let proyecto = await authAxios.get(`/admin/mdlo_DatosPrytYModulos__IdEstIdPryt/${idEstudiante}/${idProyecto}`)
   //console.log(proyecto.data)
    dispatch({
      type: LISTAR_PROYECTO,
      payload: {
        proyecto: proyecto.data.datosProyecto,
        modulos: proyecto.data.modulos,
        calificaciones: proyecto.data.calificaciones,
        certificado: proyecto.data.certificado_prueba,
        constancia: proyecto.data.constancia,
        idMatricula: proyecto.data.idMatricula
      }
    })
  } catch (error) {
    console.log(error)
  }
}


export const removerCursos = () => async dispatch => {
    dispatch({
        type: ELIMINAR_CURSOS,
        payload: []
    })
}

export const listarCurso = idCurso => async dispatch => {
  try {

  let curso = await authAxios.get(
    `/admin/mostrarCursoId/${idCurso}`);
  let profesoresCurso = await authAxios.get(`/admin/mostrarDocentesIdCurso/${idCurso}`) 

  let payload = {
    curso: curso.data,
    profesores: profesoresCurso.data
  }

  dispatch({
    payload: payload,
    type:LISTAR_CURSO
  })
  } catch (error) {
    console.log(error)
  }

}

export const mostrarSesiones = idCurso => async dispatch => {

  dispatch({
    type: MOSTRAR_SESIONES
  })
  try {
    let sesiones = await authAxios.get(`/admin/mostrarSesionesIdCurso/${idCurso}`)                                                                                                                                              
    dispatch({
        type: MOSTRAR_SESIONES_EXITO,
        payload: sesiones.data
    })
  } catch (error) {
    console.log(error)
  }
    
}

export const mostrarPagos = (idEstudiante, idProyecto) => async dispatch => {
   
  try {
    const pagos = await authAxios.get(
      `/admin/dtMatricula_PrytMdlo__IdEst_IdPryt/${idEstudiante}/${idProyecto}`
    );
    const payload = {
        pagos: pagos.data,
        estadoPagos: pagos.status
    }
    dispatch({
        type: MOSTRAR_PAGOS,
        payload
    })
  } catch (error) {
    console.log(error)
  }
    
}

export const removerEstadoPago = () => async dispatch => {
  try {
    let payload = {
      estadoPagos: 0
  }
  dispatch({
      type: REMOVER_ESTADO_PAGOS,
      payload
  })
  } catch (error) {
    console.log(error)
  }
    
}

export const listarNotas = (idEstudiante, idCurso) => async dispatch => {
  try {
    let notas = await authAxios.get(`/admin/mostrarNotasPorSesionIdEstudianteIdCursoTodo/${idEstudiante}/${idCurso}`)
    dispatch({
      payload: notas.data,
      type: LISTAR_NOTAS
    })
  } catch (error) {
    console.log(error)
  }
  
}

export const listarRecursos = idSesion => async dispatch => {
  try {
    let recursos = await authAxios.get(`/admin/se_mostrarArchivos__IdSesion/${idSesion}`)
  dispatch({
    payload: recursos.data,
    type: LISTAR_RECURSOS
  })

  if (!recursos.data.error) {
    return {mensaje: true}
  }
  } catch (error) {
    return {mensaje:false}
  }
  

}

export const listarAsistencia = (idEstudiante, idCurso) => async dispatch => {
  
  try {
    let asistencia = await authAxios.get(`/admin/mostrarAsistenciaIdEstudianteIdCurso/${idEstudiante}/${idCurso}`)


  dispatch({
    payload: asistencia.data,
    type: LISTAR_ASISTENCIA
  })

  if (!asistencia.data.error) {
    return {termino: true}
  }
  return {termino: true}
  } catch (error) {
    console.log(error)
    return{termino: false}
  }
  
}

export const subirTareas = dataTareas => async dispatch => {

  try {
    let respuesta = await authAxios.post(
      `authAxios/admin/subirTareasEstudiante`, dataTareas
    );
    
    dispatch({
      payload: respuesta.data,
      type: SUBIR_TAREAS
    });
  } catch (error) {
    console.log(error)
  }
  
}

export const listarTareas = (idSesion) => async dispatch => {
  
  try {
    let tareas = await authAxios.get(`/admin/se_mostrarTareasIdSesionAdmin/${idSesion}`)


  dispatch({
    payload: tareas.data,
    type: LISTAR_TAREAS
  })
  } catch (error) {
    console.log(error)
  }
  
}

export const listarTareasEstudiante = (idTarea, idEstudiante) => async dispatch => {

  try {
    const tareasEstudiantes = await authAxios.get(`/admin/se_mostrarTareasIdTareaIdEstudiante/${idTarea}/${idEstudiante}`)
  
    if (!tareasEstudiantes.data.error) {

      dispatch({
        payload: tareasEstudiantes.data,
        type: LISTAR_TAREA_ESTUDIANTE
      })
      return {mensaje: true, textMensaje: 'correcto'}

    }
    return {mensaje: false, textMensaje: tareasEstudiantes.data.error}
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}

export const eliminarTareas = (idTareaEstudiante, idUsuario) => async dispatch => {
  
  try {
    let respuesta = await authAxios.delete(
      `/admin/eliminarTareaEstudianteId/${idTareaEstudiante}/${idUsuario}`,
    );
      
    if (!respuesta.data.error) {
      
      dispatch({
        payload: respuesta.data,
        type: ELIMINAR_TAREA
      })
  
      return ({mensaje: true, textMensaje: 'Se elimino correctamente'})
    }
    return ({mensaje: false, textMensaje: respuesta.data.error})
  } catch (error) {
    console.log(error)

    return {mensaje: false, textMensaje: error}
  }
  
  
}

export const listarSesion = idSesion => async dispatch => {
  try {
    let sesion = await authAxios.get(`/admin/mostrarSesionId/${idSesion}`)
   // console.log(sesion)
  dispatch({
    payload: sesion.data.datos,
    type: LISTAR_SESION_CURSO
  })
  } catch (error) {
    console.log(error)
  }
  
}

export const listarVideos = idSesion => async dispatch => {
  try {
    let videos = await authAxios.get(`/admin/mostrarVideoSesionPorIdSesion/${idSesion}`)


    dispatch({
      payload: videos.data,
      type: LISTAR_VIDEOS
    })

  } catch (error) {
    console.log(error)
  }


}

export const eliminarDatosSeciones = () => dispatch => {
  dispatch({
    payload: [],
    type: ELIMINAR_DATOS_SESIONES
  })
}
